import React from "react"
import {Grid, Typography, useTheme} from "@mui/material";
import Envelopes from "assets/images/envelopes.svg";
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";
import {graphql} from "gatsby";

// TODO: Extract locales

export default function ConfirmEmail() {
  const theme = useTheme();

  return (
    <SiteLayout>
      <Section padding={4}>
        <Grid container justifyContent="center" spacing={2} alignContent="center">
          <Grid item xs={12}>
            <Typography variant="h2" align="center" color="primary">
              Na Twój adres e-mail została wysłana wiadomość z potwierdzeniem konta użytkownika.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" align="center">
              Aby dokończyć swoją rejestrację <strong>kliknij w link.</strong>
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" sx={{ color: theme.palette.primary.main }}>
            <Envelopes/>
          </Grid>
        </Grid>
      </Section>
    </SiteLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
